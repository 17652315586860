import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getSettlementPrices } from '../../dependency/analytics-server';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import moment from 'moment';
import { commaSeparatedPrice } from '../../util';
import { Duration, Flex } from '../../styles/constant';

const DeliveryPricesContainer = styled.div`
  width: 100%;
  justify-content: center;
  padding: 50px 150px;
`;

const StyledToolTip = styled.div`
  background: #18181b;
  border: 1px solid ${({ theme }) => theme.bgBalance};
  padding: 8px 10px;
  border-radius: 6px;
  text-align: center;

  p {
    color: ${({ theme }) => theme.colorGray};
    font-size: 12px;
    line-height: 14px;
    margin: 0;
    margin-bottom: 8px;
    width: 100%;
  }
  h5 {
    color: ${({ theme }) => theme.colorMain};
    font-size: 14px;
    margin: 0;
    width: 100%;
  }
`;

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && typeof payload[0] !== 'undefined') {
    return (
      <StyledToolTip>
        <p>{moment(payload[0].payload.date).utc().format('MM/DD/YY')}</p>
        {isNaN(payload[0].payload.synquote) ? (
          ''
        ) : (
          <h5>{`Synquote: $${commaSeparatedPrice(payload[0].payload.synquote)}`}</h5>
        )}
        {isNaN(payload[0].payload.deribit) ? (
          ''
        ) : (
          <h5>{`Deribit: $${commaSeparatedPrice(payload[0].payload.deribit)}`}</h5>
        )}
      </StyledToolTip>
    );
  }
  return null;
};

type DataPoint = {
  date: string;
  deribit: string;
  synquote: string;
};

type PageProps = {
  coin: string;
};

const getTickIncrement = (coin: string): number => {
  switch (coin) {
    case 'ETH':
      return 200;
    case 'BTC':
      return 1000;
    case 'LINK':
      return 5;
    default:
      return 100;
  }
};

const getSlip = (coin: string): number => {
  switch (coin) {
    case 'ETH':
      return 100;
    case 'BTC':
      return 1000;
    case 'LINK':
      return 1;
    default:
      return 0;
  }
};

const durations: any[] = [
  {
    slug: '7D',
  },
  {
    slug: '30D',
  },
  {
    slug: '1Y',
  },
];

export const DeliveryPrices: React.FC<PageProps> = ({ coin }) => {
  const [data, setData] = useState<DataPoint[]>([]);
  const [min, setMin] = useState<number>(0);
  const [max, setMax] = useState<number>(0);
  const [ticks, setTicks] = useState<number[]>([]);
  const [duration, setDuration] = useState<any>(durations[0]);
  const networkId: number = 137;

  const getData = async (currDuration: string = '7D') => {
    let startTime: number = Date.now();
    let endTime: number = Date.now();

    if (currDuration === '7D') {
      startTime = startTime - 7 * 24 * 60 * 60 * 1000;
    } else if (currDuration === '30D') {
      startTime = startTime - 30 * 24 * 60 * 60 * 1000;
    } else if (currDuration === '1Y') {
      startTime = startTime - 365 * 24 * 60 * 60 * 1000;
    }

    const resp: any[] = await getSettlementPrices(networkId, coin, startTime, endTime);
    let prices: number[] = [];
    const dataPoints: DataPoint[] = resp.map((value) => {
      prices.push(value.deribit_price);
      prices.push(value.synquote_price);
      return {
        date: moment(value.timestamp).utc().format('MM/DD/YY'),
        deribit: coin != 'LINK' ? value.deribit_price : 'none',
        synquote: value.synquote_price.toFixed(2),
      };
    });
    setData(dataPoints);
    console.log('Coin is: ', coin);
    console.log('Data points are: ', dataPoints);

    const maxPrice: number = Math.max(...prices);
    const minPrice: number = Math.min(...prices);
    let newTicks: number[] = [];
    let ticker: number = +minPrice.toFixed(0);
    while (ticker < maxPrice) {
      newTicks.push(ticker);
      ticker += getTickIncrement(coin);
    }
    newTicks.push(ticker);

    setMin(minPrice);
    setMax(maxPrice);
    setTicks(newTicks);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <DeliveryPricesContainer>
      <Flex justify="space-between" width={'80%'}>
        <h1>{`${coin}`}</h1>
        <Flex>
          {durations.map((item: any, index: number) => (
            <Duration
              key={`duration-${index}`}
              active={item.slug === duration.slug}
              onClick={() => {
                setDuration(item);
                getData(item.slug);
              }}
            >
              {item.slug}
            </Duration>
          ))}
        </Flex>
      </Flex>
      <ResponsiveContainer width={'80%'} height={400}>
        <LineChart data={data}>
          <XAxis dataKey="date" />
          <YAxis domain={[min - getSlip(coin), max + getSlip(coin)]} ticks={ticks} />
          <Tooltip cursor={false} content={<CustomTooltip />} />
          <Legend />
          <Line type="linear" dataKey="synquote" stroke="#7251F8" />
          {coin != 'LINK' ? <Line type="linear" dataKey="deribit" stroke="#82ca9d" /> : ''}
        </LineChart>
      </ResponsiveContainer>
    </DeliveryPricesContainer>
  );
};

DeliveryPrices.displayName = 'DeliveryPrices';
