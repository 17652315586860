import { useRoutes } from 'react-router-dom';

import { AggDeliveryPrice } from '../features/delivery-prices';

export const AppRoutes = () => {

  const commonRoutes = [
    { path: '/', element: <AggDeliveryPrice /> }
  ];

  const element = useRoutes([...commonRoutes]);

  return <>{element}</>;
};