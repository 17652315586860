import * as React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from '../components/elements';
import { Navbar } from '../components/navbar/navbar';

const ErrorFallback = () => {
  return (
    <div>
      <h2>Error</h2>
      <Button onClick={() => window.location.assign(window.location.origin)}>
        Refresh
      </Button>
    </div>
  );
};

const MainContainer = styled.section`
  min-height: 100vh;
  max-width: 1440px;
  margin: 0 auto;  
`;

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <React.Suspense
      fallback={
        <div>
          Spinner Comes Here
        </div>
      }
    >
        <HelmetProvider>
              <Router>
                <Navbar />
                  <MainContainer>
                    {children}
                  </MainContainer>
              </Router>
        </HelmetProvider>
    </React.Suspense>
  );
};