import * as React from 'react';
import styled from 'styled-components';
import logoImg from '../../../assets/images/synquote-logo.png';

const LogoStyles = styled.img<{ width: number; height: number}>`
  width: ${({width}) => width};
  height: ${({height}) => height};
  margin-right: 2.3rem;
`;

export type LogoProps = {
  width?: number,
  height?: number
};

export const Logo = ({ width = 24, height = 32 }: LogoProps) => {
  return (
    <LogoStyles
      width={width}
      height={height}
      src={logoImg}
    />
  );
}

Logo.displayName = 'Logo';