import { ContentLayout } from '../../components/layouts/content-layout';
import { DeliveryPrices } from '../../features/delivery-prices/delivery-prices';

export const AggDeliveryPrice: React.FC = () => {
  return (
    <ContentLayout title="Delivery Prices">
      <DeliveryPrices coin={'ETH'} />
      <DeliveryPrices coin={'BTC'} />
    </ContentLayout>
  );
};
