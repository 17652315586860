import axios from 'axios';
import { getChainNameFromId } from '../util';

const analytics_server_client: any = axios.create();

const ENDPOINT: string = 'https://analytics.synquote.com/graphql';

const coinToStableStart: Map<string, number> = new Map<string, number>([
  ['ETH', 1648598400000],
  ['BTC', 1648598400000],
  ['LINK', 1652605200000],
]);

export const getSettlementPrices = async (
  networkId: number,
  coin: string,
  start: number,
  end: number,
): Promise<any[]> => {
  let stableStart: number = start;
  // @ts-ignore
  if (coinToStableStart.has(coin) && start < coinToStableStart.get(coin)) {
    // @ts-ignore
    stableStart = coinToStableStart.get(coin);
  }

  try {
    const query: string = `
       {
          getSettlementPrices(chain:${getChainNameFromId(
            networkId,
          )}, index:${coin.toUpperCase()}_USD, time_start:${stableStart}, time_end:${end}) {
            settlement_price {
              timestamp
              index
              deribit_price
              synquote_price
            }
          }
       }
    `;
    const resp: any = await analytics_server_client.post(ENDPOINT, { query: query });
    return resp['data']['data']['getSettlementPrices']['settlement_price'];
  } catch (err) {
    console.log('[Analytics-Server] Error: ', err);
    return [];
  }
};
