import styled from 'styled-components';

import { AppProvider } from './providers/app';
import { AppRoutes } from './routes';
import { theme } from './assets/theme';

const AppContainer = styled.section`
  background: ${theme.bgColor};
  color: ${theme.defaultFontColor};
`;

function App() {
  return (
    <AppContainer>
      <AppProvider>
        <AppRoutes />
      </AppProvider>
    </AppContainer>
  );
}

export default App;
