import styled from 'styled-components';

interface FlexProps {
  gap?: string;
  col?: boolean;
  justify?: string;
  align?: string;
  margin?: string;
  colRes?: string;
  wrap?: string;
  width?: string;
  shrink?: boolean;
  hide?: string;
  reverse?: boolean;
}

export const Duration = styled.span<{ active: boolean }>`
  color: ${(props) =>
  props.active ? props.theme.colorMain : props.theme.colorGray};
  font-weight: ${(props) => (props.active ? 'bold' : 'normal')};
  padding-top: 15px;
  padding-left: 20px;
  font-size: 14px;
  line-height: 14px;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.colorMain};
  }
`;

export const Flex = styled.div<FlexProps>`

  p {
    &.date-heading {
      text-transform: uppercase;
    }
  }

  display: flex;
  ${(props) =>
  props.gap
    ? `
    gap: ${props.gap};
    & > * {
      width: 100%;
    }
  `
    : 0};
  flex-direction: ${(props) => (props.col ? 'column' : 'row')};
  flex-wrap: ${(props) => props.wrap ?? 'nowrap'};
  ${(props) => (props.justify ? 'justify-content: ' + props.justify + ';' : '')}
  ${(props) => (props.align ? 'align-items: ' + props.align + ';' : '')}
  ${(props) => (props.margin ? 'margin: ' + props.margin + ';' : '')}
  ${(props) => (props.width ? 'width: ' + props.width + ';' : '')}
  ${(props) =>
  props.colRes
    ? `
    @media (max-width: ${
      props.colRes === 'sm'
        ? '767'
        : props.colRes === 'md'
          ? '991'
          : props.colRes === 'lg'
            ? '1023'
            : props.colRes === 'xl'
              ? '1279'
              : '1439'
    }px) {
      flex-direction: column${props.reverse ? '-reverse' : ''};
      align-items: center;
      text-align: center;
    }
  `
    : ''}
  ${(props) => (props.shrink ? 'flex-shrink: 0;' : '')}
  ${(props) =>
  props.hide
    ? `
    @media (max-width: ${
      props.hide === 'sm'
        ? '767'
        : props.hide === 'md'
          ? '991'
          : props.hide === 'lg'
            ? '1023'
            : props.hide === 'xl'
              ? '1279'
              : '1439'
    }px) {
      display: none;
    }
  `
    : ''}
`;