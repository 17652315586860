import * as React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Logo } from '../elements';
import { theme } from '../../assets/theme';

const NavbarStyles = styled.header`
  background: ${theme.bgNavbar};
  display: flex;
  align-items: center;
  width: 100%;
  height: 52px;
  padding: 0 3rem;
  box-sizing: border-box;
  margin: 0 auto;
  margin-bottom: 2.5rem;

  a {
    color: ${theme.defaultFontColor};
    text-decoration: none;
  }
`;

export const Navbar = () => {
  return (
    <NavbarStyles>
      <Logo />
      <Link to="/">Delivery Prices</Link>
    </NavbarStyles>
  );
};