import * as React from 'react';
import styled from 'styled-components';

import { Head } from '../head';

const ContentStyles = styled.section`
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
`;

type ContentLayoutProps = {
  children: React.ReactNode;
  title: string;
};

export const ContentLayout = ({ children, title }: ContentLayoutProps) => {
  return (
    <ContentStyles>
      <Head title={title} />
      <main>{children}</main>
    </ContentStyles>
  );
};