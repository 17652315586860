export const getChainNameFromId = (networkId: number): string => {
  switch (networkId) {
    case 137:
      return 'POLYGON';
    case 43114:
      return 'AVAX';
    default:
      console.log(`Unsupported networkId: ${networkId}`);
      return '';
  }
};

export const commaSeparatedPrice = (value: any) => {
  let price: string = Number(value).toFixed(2);
  let [lead, decimal] = price.split('.');
  lead = Number(lead).toLocaleString('en-US');
  price = lead + '.' + decimal;
  return price;
};
