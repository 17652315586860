import * as React from 'react';
import styled from 'styled-components';

import { theme } from '../../../assets/theme';

const ButtonStyles = styled.button<{ radius: number; width: number; height: number;}>`
  display: flex;
  font-size: 0.9rem;
  justify-content: center;
  align-items: center;
  border: 0;
  border-radius: ${({radius}) => radius}px;
  width: ${({width}) => width}px;
  height: ${({height}) => height}px; 
  background: ${theme.bgButton};
  color: ${theme.defaultFontColor};
  opacity: 0.5;

  :hover {
    cursor: pointer;
    opacity: 0.7;
  }

  :disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }

  :focus {
    outline: none;
  }
`;

type IconProps =
  | { startIcon: React.ReactElement; endIcon?: never }
  | { endIcon: React.ReactElement; startIcon?: never }
  | { endIcon?: undefined; startIcon?: undefined };

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: string;
  width?: number;
  height?: number;
  isLoading?: boolean;
  radius?: number;
} & IconProps;

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      type = 'button',
      className = '',
      variant = 'primary',
      width = 150,
      height = 40,
      isLoading = false,
      startIcon,
      endIcon,
      radius = 4,
      ...props
    },
    ref
  ) => {
    return (
      <ButtonStyles
        ref={ref}
        type={type}
        className={`${className} ${variant}`}
        width={width}
        height={height}
        radius={radius}
        {...props}
      >
        {isLoading && <p>Loading</p>}
        {!isLoading && startIcon}
        <span>{props.children}</span> {!isLoading && endIcon}
      </ButtonStyles>
    );
  }
);

Button.displayName = 'Button';